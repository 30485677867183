import {getAudiobookNews, getNewPacks, getRecommendations} from './services/lunii';
import {page as page_type} from './types';
import {convertLangToCatalog} from './utils';

const addProduct = (pack: any, products: any, lang: string, trackingFromDataSource: string) => {
    pack.product = {
        ...products[`${convertLangToCatalog(lang)}-${pack.reference}`],
        trackingFromDataSource
    };
    // if (targetFlamOnlyAudiobook(pack?.product?.deviceTarget)) return null; // FLAM_MULTI_PRODUCT: hide FLAM audiobooks from new packs, news and recommendations
    return pack;
};

const getItemsFromAudiobookNews = async (context: any, page: page_type, trackingFromDataSource: string) => {
    const audiobookNews = await getAudiobookNews({graphql: context.context.graphql, locale: page.lang}) || [];
    const cacheProducts = await context.context.cache.get('lunii_product_by_ids') || {};
    return audiobookNews.map((news: any) => addProduct(news, cacheProducts, page.lang, trackingFromDataSource)).filter((x: any) => !!x);
};

const getItemsFromNewPacks = async (context: any, page: page_type, trackingFromDataSource: string, target: 'fah' | 'flam' = 'fah') => {
    const newPacks = await getNewPacks({graphql: context.context.graphql, locale: page.lang, target}) || [];
    const cacheProducts = await context.context.cache.get('lunii_product_by_ids') || {};
    newPacks.map((pack: any) => addProduct(pack, cacheProducts, page.lang, trackingFromDataSource));
    return newPacks.sort((a: any, b: any) => b.product.creationDate - a.product.creationDate).filter((x: any) => !!x);
};

const getItemsFromRecommendations = async (context: any, page: page_type, trackingFromDataSource: string, target: 'fah' | 'flam' = 'fah') => {
    const recommendations = await getRecommendations({graphql: context.context.graphql, locale: page.lang, target}) || [];
    const cacheProducts = await context.context.cache.get('lunii_product_by_ids') || {};
    recommendations.map((pack: any) => addProduct(pack, cacheProducts, page.lang, trackingFromDataSource));
    return recommendations.sort((a: any, b: any) => b.product.creationDate - a.product.creationDate).filter((x: any) => !!x);
};

export const getItemsFromDataSource = async (datasource: string, context: any, page: page_type) => {
    switch (datasource) {
        case 'audiobook_news':
            return getItemsFromAudiobookNews(context, page, 'nos_histoires_luniistore_les_nouveautes');
        case 'new_packs':
            return getItemsFromNewPacks(context, page, 'nos_histoires_luniistore_les_petits_nouveaux', 'fah');
        case 'recommendations':
            return getItemsFromRecommendations(context, page, 'nos_histoires_luniistore_notre_selection', 'fah');
        case 'new_packs_flam':
            return getItemsFromNewPacks(context, page, 'nos_histoires_luniistore_les_petits_nouveaux', 'flam');
        case 'recommendations_flam':
            return getItemsFromRecommendations(context, page, 'nos_histoires_luniistore_notre_selection', 'flam');
        default:
            return false;
    }
};
