import {config} from 'dotenv';

config({path: '.env.development'}); // silent if file not exist
config({path: '.env.local'});

export const siteUrl = `https://${process.env.FRONT_DNS}`;
export const siteSteliiUrl = `https://${process.env.FRONT_STELII_DNS}`;
export const defaultLang = 'fr-fr';

export const stores: any = {
    'fr-fr': ['fr-fr', 'fr-be', 'fr-ch', 'fr-lu', 'fr-mc'],
    'fr-ca': ['fr-ca'],
    'en-us': ['en-us', 'en-gb', 'en-ca', 'nl-nl'],
    'it-it': ['it-it'],
    'es-es': ['es-es'],
};

export const langs = Object.values(stores).reduce((acc: string[], store: any) => acc.concat(store), [])

export const mapLocaleToDateFnsLang = (locale: string | undefined = 'fr') => {
    let dateFnsLang = 'fr'; // default
    switch (locale) {
        case 'fr-fr':
        case 'fr-lu':
        case 'fr-mc':
            dateFnsLang = 'fr';
            break;
        case 'fr-be':
            dateFnsLang = 'be';
            break;
        case 'fr-ch':
            dateFnsLang = 'fr-CH';
            break;
        case 'fr-ca':
            dateFnsLang = 'fr-CA';
            break;
        case 'en-gb':
            dateFnsLang = 'en-GB';
            break;
        case 'en-us':
            dateFnsLang = 'en-US';
            break;
        case 'en-ca':
            dateFnsLang = 'en-CA';
            break;
        case 'de-de':
            dateFnsLang = 'de';
            break;
        case 'it-it':
            dateFnsLang = 'it';
            break;
        case 'es-es':
            dateFnsLang = 'es';
            break;
        case 'nl-nl':
            dateFnsLang = 'nl';
            break;
        default:
            break;
    }
    return dateFnsLang;
};

export const homeVisibleStores = [
    {
        lang: 'fr-fr',
        label: 'France (Français)',
    },
    {
        lang: 'fr-be',
        label: 'Belgique (Français)',
    },
    {
        lang: 'fr-lu',
        label: 'Luxembourg (Français)',
    },
    {
        lang: 'fr-mc',
        label: 'Monaco (Français)',
    },
    {
        lang: 'fr-ch',
        label: 'Suisse (Français)',
    },
    {
        lang: 'fr-ca',
        label: 'Canada (Français)',
    },
    {
        lang: 'en-gb',
        label: 'United Kingdom (English)',
    },
    {
        lang: 'en-us',
        label: 'USA (English)',
    },
    {
        lang: 'en-ca',
        label: 'Canada (English)',
    },
    {
        lang: 'de-de',
        label: 'Deutschland (Deutsch)',
    },
    {
        lang: 'it-it',
        label: 'Italia (Italiano)',
    },
    {
        lang: 'es-es',
        label: 'España (Español)',
    },
    {
        lang: 'nl-nl',
        label: 'Nederland (Engels)',
    },
];

export const browserLocaleMapping = {
    fr: 'fr-fr',
    'fr-fr': 'fr-fr',
    'fr-be': 'fr-be',
    'fr-lu': 'fr-lu',
    'fr-mc': 'fr-mc',
    'fr-ch': 'fr-ch',
    'fr-ca': 'fr-ca',
    en: 'en-gb',
    'en-gb': 'en-gb',
    'en-us': 'en-us',
    'en-ca': 'en-ca',
    de: 'de-de',
    'de-de': 'de-de',
    it: 'it-it',
    'it-it': 'it-it',
    es: 'es-es',
    'es-es': 'es-es',
    nl: 'nl-nl',
    'nl-nl': 'nl-nl',
};
export const getProductPagePrefixMapping: any = (locale: string, target: 'fah' | 'flam' = 'fah') => {
    const endpoint = target === 'flam' ? '-flam' : '';
    const prefixes: any = {
        'fr-fr': '/{lang}/luniistore-catalogue',
        'fr-be': '/{lang}/luniistore-catalogue',
        'fr-lu': '/{lang}/luniistore-catalogue',
        'fr-mc': '/{lang}/luniistore-catalogue',
        'fr-ch': '/{lang}/luniistore-catalogue',
        'fr-ca': '/{lang}/luniistore-catalogue',
        'en-gb': '/{lang}/luniistore-catalogue',
        'en-us': '/{lang}/luniistore-catalogue',
        'en-ca': '/{lang}/luniistore-catalogue',
        'nl-nl': '/{lang}/luniistore-catalogue',
        'de-de': '/{lang}/luniistore-katalog',
        'it-it': '/{lang}/luniistore-catalogo',
        'es-es': '/{lang}/stelii-catalogo',
        default: '/{lang}/luniistore-catalogue',
    }
    return `${prefixes[locale] || prefixes['default']}${endpoint}`;
}

export const prismicPagePrefixMapping: {[key: string]: string} = {
    'fr-fr': '/{lang}',
    'fr-be': '/{lang}',
    'fr-lu': '/{lang}',
    'fr-mc': '/{lang}',
    'fr-ch': '/{lang}',
    'fr-ca': '/{lang}',
    'en-gb': '/{lang}',
    'en-us': '/{lang}',
    'en-ca': '/{lang}',
    'nl-nl': '/{lang}',
    'de-de': '/{lang}',
    'it-it': '/{lang}',
    'es-es': '/{lang}',
    default: '/{lang}',
};

export const excludeNorthAmerica = (locale: string | undefined) =>
    ['us', 'ca'].every((lang: string) => lang !== (locale || '').substring(3, 5).toLowerCase());

export const acceptedLocalesForSubscription = ['fr-fr', 'fr-be', 'fr-mc', 'fr-ch', 'fr-lu'];

export const availableForSubscription = (locale: string | undefined) =>
    acceptedLocalesForSubscription.indexOf((locale || '').toLowerCase().replace('_', '-')) !== -1;

export const stripePk = process.env.GATSBY_STRIPE_PK;
export const stripePkUS = process.env.GATSBY_STRIPE_PK_US;

export const apiUrl = process.env.GATSBY_API_ENDPOINT;

export const manifest = {
    name: 'Lunii',
    short_name: 'Lunii',
    start_url: '/',
    background_color: '#f7f0eb',
    theme_color: '#ffffff',
    display: 'standalone',
};

export const excludePreviewPaths = ['/*/preview$', '/*/preview-*', '/*/previews$', '/*/previews-*']; // add to robots_prod in case we want to use previews in production

export const robots_prod = {
    policy: [
        {
            userAgent: '*',
            allow: ['/'],
            disallow: ['*.pdf', '/my', '/checkout', '/subscription'],
        },
    ],
};

export const robots_dev = {
    policy: [
        {
            userAgent: '*',
            disallow: ['/'],
        },
    ],
};

export const html = {
    lang: 'fr',
};

export const prismic = {
    repository: process.env.PRISMIC_REPOSITORY_NAME,
    accessToken: process.env.PRISMIC_API_ACCESS_TOKEN,
};

export const gtm = {
    id: process.env.GATSBY_GTM_ID,
    auth: process.env.GATSBY_GTM_AUTH,
    preview: process.env.GATSBY_GTM_PREVIEW || undefined,
    dataLayerName: process.env.GATSBY_GTM_DATALAYER_NAME || undefined,
    env: process.env.GATSBY_GTM_ENV,
};

export const hotjar = {
    id: process.env.GATSBY_HOTJAR_ID,
    sv: process.env.GATSBY_HOTJAR_SV,
};

export const storageKeys = {
    user: 'lunii_user',
    cart: 'lunii_cart',
    locale: 'lunii_locale',
    lastPurchase: 'lunii_last_purchase',
};

export const s3 = {
    // hardcoded url to ensure all environments are served
    prismicCache: 'https://prod-lunii-site-front-prismic-cache.s3.eu-west-3.amazonaws.com/'
};

export const pageModelItemsTracking = ['a_la_une', 'accueil'];
