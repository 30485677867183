const productRecommendations = `
    id
    objectId
    catalog
    creationDate
    locale 
    reference
    slug
    version
    name
    subtitle
    image { url }
    currency
    oldPrice
    price
    priceExclTax
    reduction
    ageMin
    type
    duration
    stock
    deviceTarget
    localesAvailable { id name slug }
    types { id name slug }
    themes { id name slug }
    ageRecommendations { id name slug }
`;

export async function GET_PRISMIC_TEMPLATES(g: Function) {
    return g(`
        query {
            allPrismicTemplate {
                edges {
                    node {
                        uid
                        type
                        lang
                        alternate_languages {uid lang type}
                        data {
                            seo_author
                            seo_subject
                            seo_title
                            charset viewport
                            seo_title seo_description seo_robots seo_keywords seo_author seo_subject seo_copyright seo_language seo_abstract seo_designer
                            http_refresh http_content_security_policy http_content_type http_x_ua_compatible
                            twitter_card twitter_site twitter_title twitter_description twitter_creator
                            opengraph_url opengraph_type opengraph_title opengraph_locale opengraph_description
                            opengraph_image {
                                url
                            }
                            opengraph_locale_alternate opengraph_site_name opengraph_determiner opengraph_video {link_type url} opengraph_audio {link_type url}
                            facebook_app_id facebook_page_id
                            theme {uid lang}
                            theme_uid
                            body1 {
                                ... on PrismicTemplateBody1AppBar {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        image {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        menu {
                                            uid lang
                                            document {
                                                ... on PrismicMenu {
                                                    id lang uid type
                                                    data {
                                                        menu_image { url alt copyright dimensions { height width } }
                                                        body {
                                                            ... on PrismicMenuBodyMenuItem {
                                                                id slice_type
                                                                items {
                                                                    label {raw}
                                                                    excluded_locales
                                                                    target {type uid lang link_type url}
                                                                }
                                                                primary {
                                                                    label {raw}
                                                                    priority
                                                                    excluded_locales
                                                                    target {uid url type link_type lang}
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        section_title {raw}
                                    }
                                }
                                ... on PrismicTemplateBody1Custom {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        section_title {raw}
                                        config
                                    }
                                }
                                ... on PrismicTemplateBody1Footer {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        image {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        menu {
                                            uid lang
                                            document {
                                                ... on PrismicMenu {
                                                    id lang uid type
                                                    data {
                                                        body {
                                                            ... on PrismicMenuBodyMenuItem {
                                                                id slice_type
                                                                items {
                                                                    excluded_locales
                                                                    label {raw}
                                                                    target {type uid lang link_type url}
                                                                }
                                                                primary {
                                                                    excluded_locales
                                                                    label {raw}
                                                                    priority
                                                                    target {uid url type link_type lang}
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        share_menu {
                                            uid lang
                                            document {
                                                ... on PrismicMenu {
                                                    id lang uid type
                                                    data {
                                                        body {
                                                            ... on PrismicMenuBodyMenuItem {
                                                                id slice_type
                                                                items {
                                                                    excluded_locales
                                                                    label {raw}
                                                                    target {type uid lang link_type url}
                                                                }
                                                                primary {
                                                                    excluded_locales
                                                                    label {raw}
                                                                    priority
                                                                    target {uid url type link_type lang}
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        section_title {raw}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
}

export async function GET_PRISMIC_PAGES(g: Function) {
    return g(`
        query {
            allPrismicPage {
                edges {
                    node {
                        uid
                        type
                        lang
                        alternate_languages {uid lang type}
                        data {
                            seo_author
                            seo_subject
                            seo_title
                            charset viewport
                            seo_title seo_description seo_robots seo_keywords seo_author seo_subject seo_copyright seo_language seo_abstract seo_designer
                            http_refresh http_content_security_policy http_content_type http_x_ua_compatible
                            twitter_card twitter_site twitter_title twitter_description twitter_creator
                            opengraph_url opengraph_type opengraph_title opengraph_locale opengraph_description
                            opengraph_image {
                                url
                            }
                            opengraph_locale_alternate opengraph_site_name opengraph_determiner opengraph_video {link_type url} opengraph_audio {link_type url}
                            facebook_app_id facebook_page_id
                            page_model page_slug excluded_locales
                            theme {uid lang} theme_uid product
                            url_android_target {link_type url uid lang type} url_ios_target {link_type url uid lang type}
                            body1 {
                                ... on PrismicPageBody1AlertBanner {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid lang} theme_uid product section_id closable
                                        text {raw} section_title {raw}
                                        excluded_locales
                                        target {uid url type link_type lang}
                                    }
                                }
                                ... on PrismicPageBody1AppBar {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id 
                                        excluded_locales
                                        image {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        menu {
                                            uid lang
                                            document {
                                                ... on PrismicMenu {
                                                    id lang uid type
                                                    data {
                                                        menu_image { url alt copyright dimensions { height width } }
                                                        body {
                                                            ... on PrismicMenuBodyMenuItem {
                                                                id slice_type
                                                                items {
                                                                    excluded_locales
                                                                    label {raw}
                                                                    target {type uid lang link_type url}
                                                                }
                                                                primary {
                                                                    excluded_locales
                                                                    label {raw}
                                                                    priority
                                                                    target {uid url type link_type lang}
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        section_title {raw}
                                    }
                                }
                                ... on PrismicPageBody1Custom {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        excluded_locales
                                        section_title {raw}
                                        config
                                    }
                                }
                                ... on PrismicPageBody1Footer {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        excluded_locales
                                        image {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        menu {
                                            uid lang
                                            document {
                                                ... on PrismicMenu {
                                                    id lang uid type
                                                    data {
                                                        body {
                                                            ... on PrismicMenuBodyMenuItem {
                                                                id slice_type
                                                                items {
                                                                    excluded_locales
                                                                    label {raw}
                                                                    target {type uid lang link_type url}
                                                                }
                                                                primary {
                                                                    excluded_locales
                                                                    label {raw}
                                                                    priority
                                                                    target {uid url type link_type lang}
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        share_menu {
                                            uid lang
                                            document {
                                                ... on PrismicMenu {
                                                    id lang uid type
                                                    data {
                                                        body {
                                                            ... on PrismicMenuBodyMenuItem {
                                                                id slice_type
                                                                items {
                                                                    excluded_locales
                                                                    label {raw}
                                                                    target {type uid lang link_type url}
                                                                }
                                                                primary {
                                                                    excluded_locales
                                                                    label {raw}
                                                                    priority
                                                                    target {uid url type link_type lang}
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        section_title {raw}
                                    }
                                }
                                ... on PrismicPageBody1Heading {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        excluded_locales
                                        image {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        image_2 {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        title {raw} overline {raw} description {raw} text {raw} section_title {raw}
                                        button_label {raw} button_2_label {raw} 
                                        button_type button_2_type
                                        button_target {link_type url uid lang type} button_2_target {link_type url uid lang type}
                                    }
                                }
                                ... on PrismicPageBody1Image {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        excluded_locales
                                        image {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        label {raw} section_title {raw}
                                    }
                                }
                                ... on PrismicPageBody1Items {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        excluded_locales
                                        data_source
                                        title {raw} text {raw} button_label {raw} section_title {raw}
                                        button_target {link_type url uid lang type}
                                        button_type
                                        image {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        items_image_position
                                    }
                                    items {
                                        visibility
                                        excluded_locales
                                        title {raw} overline {raw} label {raw} text {raw}
                                        image {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        image_2 {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        image_3 {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        button_label {raw} button_2_label {raw} button_3_label {raw}
                                        button_target {link_type url uid lang type} button_2_target {link_type url uid lang type} button_3_target {link_type url uid lang type}
                                        button_type button_2_type button_3_type
                                        theme {uid type} theme_uid
                                        image_position
                                        product
                                    }
                                }
                                ... on PrismicPageBody1Paragraph {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        excluded_locales
                                        section_title {raw}
                                    }
                                    items {
                                        visibility
                                        title {raw} subtitle {raw} text {raw}
                                    }
                                }
                                ... on PrismicPageBody1ProductHeader {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        excluded_locales
                                        section_title {raw}
                                        image {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        button_android_target {link_type url uid lang type} button_ios_target {link_type url uid lang type}
                                    }
                                    items {
                                        visibility
                                        title {raw}
                                        target {link_type url}
                                    }
                                }
                                ... on PrismicPageBody1ProductSpecs {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        excluded_locales
                                        title {raw} section_title {raw}
                                        product_specification {
                                            uid
                                            lang
                                            document {
                                                ... on PrismicProductSpecification {
                                                    uid
                                                    data {
                                                        body {
                                                            ... on PrismicProductSpecificationBodyProperties {
                                                                primary {
                                                                    title {raw}
                                                                    excluded_locales
                                                                }
                                                                items {
                                                                    content {raw}
                                                                    label {raw}
                                                                    excluded_locales
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    items {
                                        visibility
                                        title {raw} subtitle {raw} description {raw}
                                        image {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        bullet_point_icon {url}
                                    }
                                }
                                ... on PrismicPageBody1PublicationHeading {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        excluded_locales
                                        title {raw} overline {raw} description {raw} section_title {raw}
                                        url {link_type url}
                                        date
                                    }
                                }
                                ... on PrismicPageBody1Push {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        excluded_locales
                                        title {raw} overline {raw} label {raw} text {raw} section_title {raw}
                                        button_label {raw} button_2_label {raw} button_2_label {raw} 
                                        button_target {link_type url uid lang type} button_2_target {link_type url uid lang type} button_3_target {link_type url uid lang type}
                                        button_type button_2_type button_3_type product
                                        image {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        image_2 {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                    }
                                }
                                ... on PrismicPageBody1Share {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        excluded_locales
                                        title {raw} section_title {raw}
                                    }
                                    items {
                                        visibility
                                        url {link_type url}
                                    }
                                }
                                ... on PrismicPageBody1Spacer {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        excluded_locales
                                        section_title {raw}
                                        size
                                    }
                                }
                                ... on PrismicPageBody1Tabs {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        excluded_locales
                                        section_title {raw}
                                    }
                                }
                                ... on PrismicPageBody1Tags {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        excluded_locales
                                        section_title {raw}
                                    }
                                    items {
                                        visibility
                                        label {raw}
                                        target {link_type url}
                                    }
                                }
                                ... on PrismicPageBody1Video {
                                    slice_type
                                    primary {
                                        mode visibility theme {uid type} theme_uid product section_id
                                        excluded_locales
                                        title {raw} section_title {raw}
                                        url {link_type url}
                                        image {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        placeholder_image {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                    }
                                }
                                ... on PrismicPageBody1PageMenu {
                                    slice_type
                                    primary {
                                        mode 
                                        excluded_locales
                                        title {raw}
                                        menu {
                                            uid lang
                                            document {
                                                ... on PrismicMenu {
                                                    id lang uid type
                                                    data {
                                                        menu_image { url alt copyright dimensions { height width } }
                                                        body {
                                                            ... on PrismicMenuBodyMenuItem {
                                                                id slice_type
                                                                items {
                                                                    label {raw}
                                                                    excluded_locales
                                                                    target {type uid lang link_type url}
                                                                }
                                                                primary {
                                                                    label {raw}
                                                                    excluded_locales
                                                                    priority
                                                                    target {uid url type link_type lang}
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                ... on PrismicPageBody1CatalogSearch {
                                    slice_type
                                    primary {
                                        mode 
                                        excluded_locales
                                    }
                                }
                                ... on PrismicPageBody1Simplesat {
                                    slice_type
                                    primary {
                                        mode visibility data_widget_id background_color section_id
                                        excluded_locales
                                        title {raw} 
                                        text {raw}
                                        button_label {raw}
                                        button_target {link_type url uid lang type}
                                        button_type
                                        section_title {raw}
                                    }
                                }
                                ... on PrismicPageBody1ECommerce {
                                    slice_type
                                    primary {
                                        mode visibility product section_id 
                                        excluded_locales
                                        text {raw}
                                        items_title {raw}
                                        section_title {raw}
                                    }
                                    items {
                                        excluded_locales
                                        image {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        image_2 {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                    }
                                }
                                ... on PrismicPageBody1DynamicItems {
                                    slice_type
                                    primary {
                                        mode 
                                        excluded_locales
                                        title {raw} 
                                        text {raw}
                                        data_source
                                    }
                                }
                                ... on PrismicPageBody1ProductListSection {
                                    slice_type
                                    primary {
                                        mode visibility section_id
                                        excluded_locales
                                        data_source
                                        title {raw} section_title {raw}
                                    }
                                    items {
                                        visibility
                                        excluded_locales
                                        product
                                        title {raw} description {raw}
                                        target {link_type url uid lang type}
                                        button_label {raw}
                                        button_target {link_type url uid lang type}
                                        button_type
                                        tag_theme
                                        tag_icon
                                        tag_label
                                        image {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                    }
                                }
                                ... on PrismicPageBody1ProductOverviewSectionSpec {
                                    slice_type
                                    primary {
                                        visibility mode product 
                                        excluded_locales
                                        text {raw}
                                        section_title {raw}
                                        section_id 
                                        tag_theme
                                        tag_icon
                                        tag_label
                                        universe
                                        items_title {raw}
                                        image_1 {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        image_1_small {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        image_2 {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        image_2_small {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        image_3 {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        image_3_small {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        image_4 {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        image_4_small {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        alert_link_label {raw} 
                                        alert_link_url {link_type url uid lang type} 
                                        alert_link_icon
                                        alert_link_icon_position
                                        alert_link_2_label {raw} 
                                        alert_link_2_url {link_type url uid lang type} 
                                        alert_link_2_icon
                                        alert_link_2_icon_position
                                        alert_button_label {raw} 
                                        alert_button_url {link_type url uid lang type} 
                                        alert_button_icon
                                        alert_button_icon_position
                                        alert_title {raw}
                                        alert_description {raw}
                                        alert_theme 
                                        alert_closable 
                                        alert_icon
                                        alert_image {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                    }
                                    items {
                                        visibility 
                                        excluded_locales
                                        title {raw} text {raw}
                                        icon
                                    }
                                }
                                ... on PrismicPageBody1HeadingSection {
                                    slice_type
                                    primary {
                                        visibility
                                        excluded_locales
                                        section_title {raw}
                                        section_id
                                        universe
                                        position
                                        variant
                                        overline {raw}
                                        title {raw}
                                        text {raw}
                                    }
                                }
                                 ... on PrismicPageBody1HeadingCatalog {
                                    slice_type
                                    primary {
                                        visibility
                                        excluded_locales
                                        section_title {raw}
                                        section_id
                                        title {raw}
                                        text {raw}
                                        icon
                                        universe
                                        image {
                                            url alt copyright dimensions { height width }
                                            thumbnails {
                                                mobile { url alt dimensions { height width } copyright }
                                                tablet { url alt dimensions { height width } copyright }
                                            }
                                        }
                                        button_label {raw} 
                                        button_target {link_type url uid lang type} 
                                        button_icon
                                        button_icon_position
                                    }
                                }
                                ... on PrismicPageBody1NewsSlider {
                                    slice_type
                                    primary {
                                        visibility
                                        excluded_locales
                                        section_title {raw}
                                        section_id
                                    }
                                }
                                ... on PrismicPageBody1CustomizableSpacer {
                                    slice_type
                                    primary {
                                        visibility
                                        excluded_locales
                                        height_desktop
                                        height_tablet
                                        height_mobile
                                        color
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
}

export async function FIND_LUNII_PRODUCTS(g: Function, {catalogs = []}: { catalogs?: string[] } = {}) {
    return g(`
        query findLuniiProducts($catalogs: [String]) {
            lunii {
                findProducts(catalogs: $catalogs) {
                    items {
                        __typename
                        ...on Lunii_DigitalAlbumProduct {
                            __typename
                            id
                            objectId
                            reference
                            name
                            creationDate
                            locale
                            slug
                            subtitle
                            image {
                                url
                            }
                            previews
                            catalog
                            type
                            description
                            reduction
                            oldPrice
                            priceExclTax
                            currency
                            price
                            ageMin
                            duration
                            canPurchaseWithSubscription
                            languagesAvailable {
                                slug
                                url
                                locale
                            }
                            localesAvailable {
                                id
                                name
                                slug
                            }
                            types {
                                id
                                name
                                slug
                                image {
                                    url
                                }
                            }
                            themes {
                                id
                                name
                                slug
                            }
                            ageRecommendations {
                                id
                                name
                                description
                                slug
                            }
                            authors {
                                name
                                gender
                                image {
                                    url
                                }
                            }
                            tellers {
                                name
                                gender
                                image {
                                    url
                                }
                            }
                            translators {
                                name
                                gender
                                image {
                                    url
                                }
                            }
                            recommendations {
                                ${productRecommendations}
                            }
                            stock
                            deviceTarget
                            uuid
                            stories {
                                title
                                duration
                                combinations
                            }
                            credits {
                                categoryTitle
                                songs {
                                    credits
                                    songName
                                }
                            }

                        }
                        ...on Lunii_PaperBookProduct {
                            id
                            reference
                            name
                            catalog
                            type
                            description
                            price
                            priceExclTax
                            stock
                        }
                        ...on Lunii_BookProduct {
                            id
                            reference
                            name
                            catalog
                            type
                            description
                            price
                            priceExclTax
                            stock
                        }
                        ...on Lunii_HardwareProduct {
                            __typename
                            id
                            objectId
                            name
                            description
                            shippingInfos
                            included {
                                title
                                description
                                imageUrl
                                action {
                                    type
                                    url
                                }
                            }
                            slug
                            creationDate
                            reference
                            catalog
                            type
                            subtype
                            version
                            price
                            oldPrice
                            priceExclTax
                            currency
                            reduction
                            locale
                            image {
                                url
                            }
                            illustration {
                                url
                            }
                            recommendations {
                                ${productRecommendations}
                            }
                            stock
                        }
                    }
                }
            }
        }
    `, {catalogs});
}

export async function GET_PRISMIC_MENU(g: Function, uid: string) {
    return g(`    
        query getLuniiMenu($uid: String) {
            allPrismicMenu(filter: {uid: {eq: $uid}}) {
                edges {
                    node {
                        ... on PrismicMenu {
                            id lang uid type
                            data {
                                menu_image { url alt copyright dimensions { height width } }
                                body {
                                    ... on PrismicMenuBodyMenuItem {
                                        id slice_type
                                        items {
                                            excluded_locales
                                            label {raw}
                                            target {type uid lang link_type url}
                                        }
                                        primary {
                                            excluded_locales
                                            label {raw}
                                            priority
                                            target {uid url type link_type lang}
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `, {uid});
}

export async function GET_AUDIOBOOKS_NEWS(g: Function, {locale = ''}: { locale: string }) {
    return g(`
        query getAudiobooksNews($locale: String) {
            lunii {
                getAudiobooksNews(locale: $locale) {
                    items {
                        title
                        description
                        keyword
                        button
                        layout
                        target
                        reference
                        audiobookId
                        slug
                        innerImage
                        backgroundImage
                        leftImage
                        rightImage
                        createdAt
                    }
                }
            }
        }
    `, {locale});
}

export async function FIND_NEW_PACKS(g: Function, {locale = '', target = 'fah'}: { locale: string, target: 'fah' | 'flam' }) {
    return g(`
        query getNewPacks($locale: String, $target: String) {
            lunii {
                findNewPacks(locale: $locale, target: $target) {
                    items {
                        reference
                    }
                }
            }
        }
    `, {locale, target});
}

export async function FIND_RECOMMENDATIONS(g: Function, {locale = '', target = 'fah'}: { locale: string, target: 'fah' | 'flam' }) {
    return g(`
        query getRecommendations($locale: String, $target: String) {
            lunii {
                findRecommendations(locale: $locale, target: $target) {
                    items {
                        reference
                    }
                }
            }
        }
    `, {locale, target});
}

export async function FIND_GIFT_CARDS_THEMES(g: Function, {locale = ''}: { locale: string }) {
    return g(`
        query getGiftCardsThemes($locale: String) {
            lunii {
                findGiftCardsThemes(locale: $locale) {
                    items {
                        image {
                            url
                        }
                        title
                        reference
                        catalog
                    }
                }
            }
        }
    `, {locale});
}
