import {
    formatModuleModeComponentName,
    slugize,
    richtextize,
    targetize,
    imagize,
    themeize,
    convertProductForLevel,
} from '../../utils';
import {module, rawmodule, page} from '../../types';
import {product} from '../../ui';
import {getItemsFromDataSource} from '../../datasources';
import {pageModelItemsTracking} from '../../../configs/site';

async function populate_from_product(product: product, module: rawmodule): Promise<rawmodule> {
    // @todo inject data from source into the raw module, as if they were populated manually
    return module;
}

async function populate_item_from_product(
    product: product,
    item: any,
    module: rawmodule,
): Promise<rawmodule> {
    // @todo inject data from source into the raw module, as if they were populated manually
    return item;
}

async function populate_from_source(
    source: string,
    module: rawmodule,
    ctx: any,
    page: page,
): Promise<rawmodule> {
    const itemsFromDataSource = await getItemsFromDataSource(source, ctx, page);
    if (itemsFromDataSource !== false) module.items = itemsFromDataSource;
    return module;
}
export async function items(module: rawmodule, page: page, ctx: any): Promise<module> {
    module?.primary?.product &&
        (module = await populate_from_product(module?.primary?.product, module));
    module?.primary?.data_source &&
        (module = await populate_from_source(module?.primary?.data_source, module, ctx, page));

    const model: string = page.pageModel || '';
    const itemsAreTracked: boolean = pageModelItemsTracking.includes(model);

    const {primary, items = []} = module;

    return {
        component: formatModuleModeComponentName('Items', primary.mode, 'PushSmall'),
        data: {
            itemsAreTracked,
            title: richtextize(primary?.title),
            text: richtextize(primary?.text),
            buttonLabel: richtextize(primary?.button_label),
            buttonType: primary?.button_type,
            buttonTarget: targetize(primary?.button_target),
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
            image: imagize(primary?.image),
            itemsImagePosition: primary?.items_image_position,
            items: await Promise.all(
                (items as any[])
                    .filter(item => (item.excluded_locales || '').split(',').indexOf(page.lang?.slice(-2)) === -1)
                    .map(async (item) => {
                    const product = await convertProductForLevel(item.product, 'item', ctx, page.lang);
                    let updatedItem = {...item};

                    if (product) {
                        updatedItem = await populate_item_from_product(product, item, module);
                    }
                    return {
                        title: richtextize(updatedItem.title),
                        text: richtextize(updatedItem.text),
                        overline: richtextize(updatedItem.overline),
                        label: richtextize(updatedItem.label),
                        buttonLabel: richtextize(updatedItem.button_label),
                        buttonType: updatedItem.button_type,
                        buttonTarget: targetize(updatedItem.button_target),
                        button2Label: richtextize(updatedItem.button_2_label),
                        button2Type: updatedItem.button_2_type,
                        button2Target: targetize(updatedItem.button_2_target),
                        button3Label: richtextize(updatedItem.button_3_label),
                        button3Type: updatedItem.button_3_type,
                        button3Target: targetize(updatedItem.button_3_target),
                        image: imagize(updatedItem.image),
                        image2: imagize(updatedItem.image_2),
                        image3: imagize(updatedItem.image_3),
                        imagePosition: updatedItem.image_position,
                        theme: themeize(updatedItem.theme_uid, updatedItem.theme?.uid),
                        product,
                    };
                }),
            ),
        },
        config: {
            richTextProps: ['title', 'text', 'buttonLabel', 'sectionTitle'],
            targetProps: ['buttonTarget'],
        },
        itemConfig: {
            richTextProps: [
                'title',
                'overline',
                'label',
                'text',
                'buttonLabel',
                'button2Label',
                'button3Label',
            ],
            targetProps: ['buttonTarget', 'button2Target', 'button3Target'],
        },
    };
}

export default items;
