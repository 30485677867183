import {formatModuleModeComponentName, richtextize, slugize} from '../../utils';
import {module, rawmodule} from '../../types';

export async function customizable_spacer({primary}: rawmodule): Promise<module> {
    return {
        component: formatModuleModeComponentName('CustomizableSpacer', 'default', 'Default'),
        data: {
            heightDesktop: primary?.height_desktop,
            heightTablet: primary?.height_tablet,
            heightMobile: primary?.height_mobile,
            color: primary?.color,
        },
    };
}

export default customizable_spacer;
