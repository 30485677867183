import {FIND_LUNII_PRODUCTS, FIND_NEW_PACKS, FIND_RECOMMENDATIONS, FIND_GIFT_CARDS_THEMES, GET_AUDIOBOOKS_NEWS} from '../queries';
import {create_pages_context, rawproduct} from '../types';
import {langs} from '../../configs/site';
import {chunk, convertLangToCatalog} from '../utils';

export async function getProducts({graphql}: create_pages_context): Promise<rawproduct[]> {
    return (await Promise.all(chunk<string>(langs, 1).map(c => FIND_LUNII_PRODUCTS(graphql, {catalogs: c.map(convertLangToCatalog)}))))
        .reduce((acc, p) => [...acc, ...(p.data?.lunii?.findProducts?.items || [])], [] as any[])
    ;
}

export async function getNewPacks({graphql, locale, target}: {graphql: Function, locale: string, target: 'fah' | 'flam'}) {
    const newPacksResponse = await FIND_NEW_PACKS(graphql, {locale: convertLangToCatalog(locale), target});
    return newPacksResponse?.data?.lunii?.findNewPacks?.items || [];
}

export async function getRecommendations({graphql, locale, target}: {graphql: Function, locale: string, target: 'fah' | 'flam'}) {
    const recommendationResponse = await FIND_RECOMMENDATIONS(graphql, {locale: convertLangToCatalog(locale), target});
    return recommendationResponse?.data?.lunii?.findRecommendations?.items || [];
}

export async function getGiftCardsThemes({graphql}: create_pages_context): Promise<any[]> {
    return (await Promise.all(langs.map(locale => FIND_GIFT_CARDS_THEMES(graphql, {locale: convertLangToCatalog(locale)}))))
        .reduce((acc, p) => [...acc, ...(p.data?.lunii?.findGiftCardsThemes?.items || [])], [] as any[]);
}

export async function getAudiobookNews({graphql, locale}: {graphql: Function, locale: string}) {
    const audiobooksResponse = await GET_AUDIOBOOKS_NEWS(graphql, {locale: convertLangToCatalog(locale)});
    return audiobooksResponse?.data?.lunii?.getAudiobooksNews?.items || [];
}
